html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

table {
  border-spacing: 0px;
}

.dropzone-area {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  transition: 0.3s;
}

.dropzone-paragraph {
  font-size: 20px;
  font-weight: bold;
  color: #09131d;
}

/*input  checkbox */
input[type="checkbox"] {
  background-color: red;
}

.ql-toolbar.ql-snow {
  border: none;
  border-top: 1px solid #e7ebed;
}

.ql-container.ql-snow {
  border: none;
}

.ql-editor {
  min-height: 150px;
}

input[type="radio"] {
  appearance: none;
  width: 16px;
  height: 16px;
  position: relative;
  border: 1px solid #e7ebed;
  border-radius: 50%;
}

input[type="radio"]:checked {
  background: #374667;
  border: none;
}

input[type="radio"]::before {
  content: "";
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  background: transparent;
  border-radius: 50%;
}

input[type="radio"]:checked::before {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.416 3.37604C12.7607 3.60581 12.8538 4.07146 12.624 4.4161L7.62404 11.9161C7.4994 12.1031 7.2975 12.2243 7.0739 12.2464C6.8503 12.2686 6.62855 12.1893 6.46967 12.0304L3.46967 9.03041C3.17678 8.73752 3.17678 8.26264 3.46967 7.96975C3.76256 7.67686 4.23744 7.67686 4.53033 7.96975L6.88343 10.3229L11.376 3.58405C11.6057 3.23941 12.0714 3.14628 12.416 3.37604Z" fill="white"/></svg>');
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-82%, -54%);
  width: 0.6rem;
  height: 0.6rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out forwards;
}

.Toastify__toast-container {
  width: auto !important;
  height: auto !important;
  min-height: "2.5rem" !important;
  min-width: "27.5rem" !important;
}

.Toastify__toast-container:has(.custom-toast-position) {
  transform: unset !important;
  left: unset !important;
  right: 9% !important;
  top: 78px !important;
}

.Toastify__toast-icon {
  width: 24px !important;
  height: 24px !important;
}
